import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '首页',
    redirect:'/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/layout/index/index.vue')
  },
  {
    path: '/convert',
    name: 'convert',
    component: () => import('../views/layout/convert/index.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
